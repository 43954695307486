const fr = {
    // Common
    'Close': 'Fermer',
    'From': 'De',
    'To': 'À',
    'Distance': 'Distance',
    'Estimated time': 'Heure d\'arrivée estimée',
    'Level': 'Niveau',
    'Search by name, category, building...': 'Recherche par nom, catégorie, bâtiment...',
    'Nothing was found': 'Rien n\'a été trouvé',
    'd': 'd',
    'h': 'h',
    'min': 'min',
    'Walk': 'À pied',
    'Bike': 'À vélo',
    'Transit': 'En transports',
    'Drive': 'Voiture',
    'Start wayfinding': 'Commencer la navigation',
    // Location Details
    'Read full description': 'Lire la description complète',
    // Locations list
    'Locations': 'Emplacements',
    'Directions not available': 'Itinéraire non disponible',
    'Start directions': 'Commencer la navigation',
    // Route instructions
    'Previous': 'Précédent',
    'Next': 'Suivant',
    'StepYofX': 'Étape {{activeStep}} sur {{totalSteps}}',
    // Route Instructions Step
    'You have arrived': 'Vous êtes arrivé',
    'Leave': 'Départ',
    'Park': 'Parc',
    'at': 'à',
    'Building': 'Bâtiment',
    'Venue': 'Lieu',
    'Take staircase to level': 'Prenez l\'escalier jusqu\'au niveau',
    'Take the ladder to level': 'Empruntez l\'escalier pour rejoindre le niveau',
    'Take elevator to level': 'Prenez l\'ascenseur jusqu\'au niveau',
    'Take escalator to level': 'Empruntez l\'escalier roulant jusqu\'au niveau',
    'Take wheelchair lift to level': 'Prenez l\'élévateur pour fauteuil roulant jusqu\'au niveau',
    'Take wheelchair ramp to level': 'Prenez la rampe pour fauteuil roulant jusqu\'au niveau',
    'Exit': 'Partir',
    'Enter': 'Entrer',
    'stops': 'arrêts',
    'and continue': 'et continuez',
    'Continue straight ahead': 'Continuez tout droit',
    'Go left': 'Tournez a gauche',
    'Go sharp left': 'Allez directement à gauche',
    'Go slight left': 'Allez légèrement à gauche',
    'Go right': 'Allez à droite',
    'Go sharp right': 'Allez directement à droite',
    'Go slight right': 'Allez légèrement à droite',
    'Turn around': 'Faite demi-tour',
    // Wayfinding component
    'Switch': 'Changer',
    'Accessibility': 'Accessibilité',
    'No route found': 'Aucun itinéraire trouvé',
    'My position': 'Ma position',
    'Turn on Accessibility to get directions that avoid stairs and escalators.': 'Activez l\'accessibilité pour obtenir des itinéraires qui évitent les escaliers.',
    'Go!': 'C\'est parti!',
    // Venue selector
    'Venues': 'Lieux',
    'Select venue': 'Sélectionner le lieu',
    'Current': 'Actuel',
    // QR code dialog
    'Scan QR code': 'Scanne le code QR',
    'Scan the QR code to see the route on your phone': 'Scannez le code QR pour voir l\'itinéraire sur votre téléphone',
    'Done': 'Fini',
     // Directions
     'Cancel route': 'Annuler l\'itinéraire',
     'Finish route': 'Terminer l\'itinéraire',
};

export default fr;
