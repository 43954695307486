const en = {
    // Common
    'Close': 'Close',
    'From': 'From',
    'To': 'To',
    'Distance': 'Distance',
    'Estimated time': 'Estimated time',
    'Level': 'Level',
    'Search by name, category, building...': 'Search by name, category, building...',
    'Nothing was found': 'Nothing was found',
    'd': 'd',
    'h': 'h',
    'min': 'min',
    'Walk': 'Walk',
    'Bike': 'Bike',
    'Transit': 'Transit',
    'Drive': 'Drive',
    'Start wayfinding': 'Start wayfinding',
    // Location Details
    'Read full description': 'Read full description',
    // Locations list
    'Locations': 'Locations',
    'Directions not available': 'Directions not available',
    'Start directions': 'Start directions',
    // Route instructions
    'Previous': 'Previous',
    'Next': 'Next',
    'StepYofX': 'Step {{activeStep}} of {{totalSteps}}',
    // Route Instructions Step
    'You have arrived': 'You have arrived',
    'Leave': 'Leave',
    'Park': 'Park',
    'at': 'at',
    'Building': 'Building',
    'Venue': 'Venue',
    'Take staircase to level': 'Take staircase to level',
    'Take the ladder to level': 'Take the ladder to level',
    'Take elevator to level': 'Take elevator to level',
    'Take escalator to level': 'Take escalator to level',
    'Take wheelchair lift to level': 'Take wheelchair lift to level',
    'Take wheelchair ramp to level': 'Take wheelchair ramp to level',
    'Exit': 'Exit',
    'Enter': 'Enter',
    'stops': 'stops',
    'and continue': 'and continue',
    'Continue straight ahead': 'Continue straight ahead',
    'Go left': 'Go left',
    'Go sharp left': 'Go sharp left',
    'Go slight left': 'Go slight left',
    'Go right': 'Go right',
    'Go sharp right': 'Go sharp right',
    'Go slight right': 'Go slight right',
    'Turn around': 'Turn around',
    // Wayfinding component
    'Switch': 'Switch',
    'Accessibility': 'Accessibility',
    'No route found': 'No route found',
    'My position': 'My position',
    'Turn on Accessibility to get directions that avoid stairs and escalators.': 'Turn on Accessibility to get directions that avoid stairs and escalators.',
    'Go!': 'Go!',
    // Venue selector
    'Venues': 'Venues',
    'Select venue': 'Select venue',
    'Current': 'Current',
    // QR code dialog
    'Scan QR code': 'Scan QR code',
    'Scan the QR code to see the route on your phone': 'Scan the QR code to see the route on your phone',
    'Done': 'Done',
    // Directions
    'Cancel route': 'Cancel route',
    'Finish route': 'Finish route',
};

export default en;
